import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'TOPBAR.FX_AREA.LIST.ADMIN.CONFIG',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.CONFIG',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.PROPERTY_TYPE',
        icon: 'bx-extension',
        link: '/application/property-type',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.CONFIG',
        badge: {
            variant: 'badge-soft-secondary',
            text: '',
        },
    },
    {
        id: 51,
        label: 'TOPBAR.FX_AREA.LIST.ADMIN.CUSTOMERS',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.CUSTOMERS',
        isTitle: true
    },
    {
        id: 52,
        label: 'MENUITEMS.CUSTOMERS',
        icon: 'bx-group',
        link: '/',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.CUSTOMERS',
        badge: {
            variant: 'badge-soft-secondary',
            text: '',
        },
    },
    {
        id: 53,
        label: 'MENUITEMS.SALES',
        icon: 'bx-dollar',
        link: '/',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.CUSTOMERS',
        badge: {
            variant: 'badge-soft-secondary',
            text: '',
        },
    },
    {
        id: 51,
        label: 'TOPBAR.FX_AREA.LIST.ADMIN.PROPERTIES',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.PROPERTIES',
        isTitle: true
    },
    {
        id: 52,
        label: 'MENUITEMS.PROPERTIES',
        icon: 'bx-list-ul',
        link: '/application/properties',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.PROPERTIES'
    },
    {
        id: 51,
        label: 'TOPBAR.FX_AREA.LIST.ADMIN.REPORTS',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.REPORTS',
        isTitle: true
    },
    {
        id: 52,
        label: 'MENUITEMS.PROPERTY_TYPE',
        icon: 'bx-stats',
        link: '/',
        area: 'TOPBAR.FX_AREA.LIST.ADMIN.REPORTS',
        badge: {
            variant: 'badge-soft-secondary',
            text: '',
        },
    },
    {
        id: 61,
        label: 'TOPBAR.FX_AREA.LIST.MANAGED',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        isTitle: true
    },
    {
        id: 62,
        label: 'MENUITEMS.MAIN',
        icon: 'bx-buildings',
        link: '/application/manage-home',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 63,
        label: 'MENUITEMS.PRESOURCES',
        icon: 'bx-bed',
        link: '/application/presources',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 64,
        label: 'MENUITEMS.PRICE-TEMPLATE',
        icon: 'bx-dollar-circle',
        link: '/application/price-template',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 65,
        label: 'MENUITEMS.RATE-PLAN',
        icon: 'bx-layer',
        link: '/application/rate-plan',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 66,
        label: 'MENUITEMS.INVENTORY',
        icon: 'bx-customize',
        link: '/application/inventory',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 67,
        label: 'MENUITEMS.BOOKING',
        icon: 'bx-dish',
        link: '/application/bookings',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 68,
        label: 'MENUITEMS.PLANNING',
        icon: 'bx-poll',
        link: '/application/planning',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    },
    {
        id: 69,
        label: 'MENUITEMS.CHAT',
        icon: 'bx-bell',
        link: '/application/chat',
        area: 'TOPBAR.FX_AREA.LIST.MANAGED.MAIN',
        badge: {
            variant: 'badge-soft-secondary',
            text: '1',
        },
    }
];
/*
export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.SALES',
        icon: 'monitor',
        link: '/',
        badge: {
            variant: 'badge-soft-secondary',
            text: 'MENUITEMS.SALES.BADGE',
        },
    },
    {
        id: 3,
        label: 'MENUITEMS.ANALYTICS',
        icon: 'pie-chart',
        link: '/'
    },
    {
        id: 4,
        label: 'MENUITEMS.APPLICATIONS',
        isTitle: true
    },
    {
        id: 5,
        label: 'MENUITEMS.CALENDAR',
        icon: 'calendar',
        link: '/'
    },
    {
        id: 5,
        label: 'MENUITEMS.CHAT',
        icon: 'message-square',
        link: '/',
        badge: {
            variant: 'badge-soft-danger',
            text: 'MENUITEMS.CHAT.BADGE',
        },
    },
    {
        id: 6,
        label: 'MENUITEMS.KANBANBOARD',
        icon: 'trello',
        link: '/'
    },
    {
        id: 7,
        label: 'MENUITEMS.FILEMANAGER',
        icon: 'folder',
        link: '/'
    },
    {
        id: 8,
        label: 'MENUITEMS.EMAIL',
        icon: 'mail',
        subItems: [
            {
                id: 9,
                label: 'MENUITEMS.EMAIL.LIST.INBOX',
                link: '/',
                parentId: 8
            },
            {
                id: 10,
                label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
                link: '/',
                parentId: 8
            }
        ]
    },
    {
        id: 11,
        label: 'MENUITEMS.CONTACTS',
        icon: 'book',
        subItems: [
            {
                id: 12,
                label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
                link: '/',
                parentId: 11
            },
            {
                id: 13,
                label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
                link: '/',
                parentId: 11
            },
            {
                id: 14,
                label: 'MENUITEMS.CONTACTS.LIST.SETTINGS',
                link: '/',
                parentId: 11
            }
        ]
    },
    {
        id: 15,
        label: 'MENUITEMS.GALLERY',
        icon: 'image',
        link: '/'
    },
    {
        id: 16,
        label: 'MENUITEMS.PROJECTS',
        icon: 'briefcase',
        subItems: [
            {
                id: 17,
                label: 'MENUITEMS.PROJECTS.LIST.PROJECTSGRID',
                link: '/',
                parentId: 16
            },
            {
                id: 18,
                label: 'MENUITEMS.PROJECTS.LIST.PROJECTSLIST',
                link: '/',
                parentId: 16
            },
            {
                id: 19,
                label: 'MENUITEMS.PROJECTS.LIST.PROJECTSOVERVIEW',
                link: '/',
                parentId: 16
            },
            {
                id: 20,
                label: 'MENUITEMS.PROJECTS.LIST.CREATENEW',
                link: '/',
                parentId: 16
            }
        ]
    },
    {
        id: 21,
        label: 'MENUITEMS.PAGES',
        isTitle: true
    },
    {
        id: 22,
        label: 'MENUITEMS.AUTHENTICATION',
        icon: 'manage',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.AUTHENTICATION.BADGE',
        },
        subItems: [
            {
                id: 23,
                label: 'MENUITEMS.AUTHENTICATION.LIST.SIGNIN',
                subItems: [
                    {
                        id: 24,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 23
                    },
                    {
                        id: 25,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 23
                    },
                ]
            },
            {
                id: 26,
                label: 'MENUITEMS.AUTHENTICATION.LIST.SIGNUP',
                subItems: [
                    {
                        id: 27,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 26
                    },
                    {
                        id: 28,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 26
                    },
                ]
            },
            {
                id: 29,
                label: 'MENUITEMS.AUTHENTICATION.LIST.SIGNOUT',
                subItems: [
                    {
                        id: 30,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 29
                    },
                    {
                        id: 31,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 29
                    },
                ]
            },
            {
                id: 32,
                label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
                subItems: [
                    {
                        id: 33,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 32
                    },
                    {
                        id: 34,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 32
                    },
                ]
            },
            {
                id: 35,
                label: 'MENUITEMS.AUTHENTICATION.LIST.FORGOTPASSWORD',
                subItems: [
                    {
                        id: 36,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 32
                    },
                    {
                        id: 37,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 32
                    },
                ]
            },
            {
                id: 38,
                label: 'MENUITEMS.AUTHENTICATION.LIST.RESETPWD',
                subItems: [
                    {
                        id: 39,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 40,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 38
                    },
                ]
            },
            {
                id: 41,
                label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
                subItems: [
                    {
                        id: 42,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 41
                    },
                    {
                        id: 43,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 41
                    },
                ]
            },
            {
                id: 44,
                label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
                subItems: [
                    {
                        id: 45,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 44
                    },
                    {
                        id: 46,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 44
                    },
                ]
            },
            {
                id: 47,
                label: 'MENUITEMS.AUTHENTICATION.LIST.THANKYOU',
                subItems: [
                    {
                        id: 48,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
                        link: '/',
                        parentId: 47
                    },
                    {
                        id: 49,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
                        link: '/',
                        parentId: 47
                    },
                ]
            }
        ]
    },
    {
        id: 50,
        label: 'MENUITEMS.ERRORSPAGES',
        icon: 'alert-circle',
        subItems: [
            {
                id: 51,
                label: 'MENUITEMS.ERRORSPAGES.LIST.404BASIC',
                link: '/',
                parentId: 50
            },
            {
                id: 52,
                label: 'MENUITEMS.ERRORSPAGES.LIST.404COVER',
                link: '/',
                parentId: 50
            },
            {
                id: 53,
                label: 'MENUITEMS.ERRORSPAGES.LIST.500BASIC',
                link: '/',
                parentId: 50
            },
            {
                id: 53,
                label: 'MENUITEMS.ERRORSPAGES.LIST.500COVER',
                link: '/',
                parentId: 50
            },
        ]
    },
    {
        id: 54,
        label: 'MENUITEMS.UTILITY',
        icon: 'file-text',
        subItems: [
            {
                id: 55,
                label: 'MENUITEMS.UTILITY.LIST.STARTER',
                link: '/',
                parentId: 54
            },
            {
                id: 56,
                label: 'MENUITEMS.UTILITY.LIST.PROFILE',
                link: '/',
                parentId: 54
            },
            {
                id: 56,
                label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
                link: '/',
                parentId: 54
            },
            {
                id: 57,
                label: 'MENUITEMS.UTILITY.LIST.COMINGSOON',
                link: '/',
                parentId: 54
            },
            {
                id: 58,
                label: 'MENUITEMS.UTILITY.LIST.FAQS',
                link: '/',
                parentId: 54
            }
        ]
    },
    {
        id: 59,
        label: 'MENUITEMS.PRICING',
        icon: 'tag',
        subItems: [
            {
                id: 60,
                label: 'MENUITEMS.PRICING.LIST.BASIC',
                link: '/',
                parentId: 59
            },
            {
                id: 61,
                label: 'MENUITEMS.PRICING.LIST.TABLE',
                link: '/',
                parentId: 59
            },
        ]
    },
    {
        id: 62,
        label: 'MENUITEMS.INVOICES',
        icon: 'file',
        subItems: [
            {
                id: 63,
                label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
                link: '/',
                parentId: 62
            },
            {
                id: 64,
                label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
                link: '/',
                parentId: 62
            },
        ]
    },
    {
        id: 65,
        label: 'MENUITEMS.TIMELINE',
        icon: 'award',
        subItems: [
            {
                id: 63,
                label: 'MENUITEMS.TIMELINE.LIST.CENTERVIEW',
                link: '/',
                parentId: 62
            },
            {
                id: 64,
                label: 'MENUITEMS.TIMELINE.LIST.LEFTVIEW',
                link: '/',
                parentId: 62
            },
            {
                id: 64,
                label: 'MENUITEMS.TIMELINE.LIST.HORIZONTALVIEW',
                link: '/',
                parentId: 62
            },
        ]
    },
    {
        id: 65,
        label: 'MENUITEMS.COMPONENTS',
        isTitle: true
    },
    {
        id: 66,
        label: 'MENUITEMS.UIELEMENTS',
        icon: 'package',
        subItems: [
            {
                id: 67,
                label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
                link: '/',
                parentId: 66
            },
            {
                id: 67,
                label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
                link: '/',
                parentId: 66
            },
            {
                id: 67,
                label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
                link: '/',
                parentId: 66
            },
            {
                id: 68,
                label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
                link: '/',
                parentId: 66
            },
            {
                id: 69,
                label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
                link: '/',
                parentId: 66
            },
            {
                id: 70,
                label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
                link: '/',
                parentId: 66
            },
            {
                id: 71,
                label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
                link: '/',
                parentId: 66
            },
            {
                id: 72,
                label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
                link: '/',
                parentId: 66
            },
            {
                id: 73,
                label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
                link: '/',
                parentId: 66
            },
            {
                id: 74,
                label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
                link: '/',
                parentId: 66
            },
            {
                id: 75,
                label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
                link: '/',
                parentId: 66
            },
            {
                id: 76,
                label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
                link: '/',
                parentId: 66
            },
            {
                id: 77,
                label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
                link: '/',
                parentId: 66
            },
            {
                id: 78,
                label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
                link: '/',
                parentId: 66
            },
            {
                id: 79,
                label: 'MENUITEMS.UIELEMENTS.LIST.UTILITIES',
                link: '/',
                parentId: 66
            }
        ]
    },
    {
        id: 80,
        label: 'MENUITEMS.EXTENDED',
        icon: 'cpu',
        subItems: [
            {
                id: 81,
                label: 'MENUITEMS.EXTENDED.LIST.LIGHTBOX',
                link: '/',
                parentId: 80
            },
            {
                id: 82,
                label: 'MENUITEMS.EXTENDED.LIST.RANGESLIDER',
                link: '/',
                parentId: 80
            },
            {
                id: 83,
                label: 'MENUITEMS.EXTENDED.LIST.SWEETALERT',
                link: '/',
                parentId: 80
            },
            {
                id: 84,
                label: 'MENUITEMS.EXTENDED.LIST.RATING',
                link: '/',
                parentId: 80
            },
            {
                id: 85,
                label: 'MENUITEMS.EXTENDED.LIST.NOTIFICATION',
                link: '/',
                parentId: 80
            },
            {
                id: 86,
                label: 'MENUITEMS.EXTENDED.LIST.SWIPERSLIDER',
                link: '/',
                parentId: 80
            }
        ]
    },
    {
        id: 87,
        label: 'MENUITEMS.WIDGETS',
        icon: 'grid',
        link: '/'
    },
    {
        id: 88,
        label: 'MENUITEMS.FORMS',
        icon: 'edit-3',
        subItems: [
            {
                id: 89,
                label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
                link: '/',
                parentId: 88
            },
            {
                id: 89,
                label: 'MENUITEMS.FORMS.LIST.VALIDATION',
                link: '/',
                parentId: 88
            },
            {
                id: 90,
                label: 'MENUITEMS.FORMS.LIST.ADVANCED',
                link: '/',
                parentId: 88
            },
            {
                id: 91,
                label: 'MENUITEMS.FORMS.LIST.EDITOR',
                link: '/',
                parentId: 88
            },
            {
                id: 92,
                label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
                link: '/',
                parentId: 88
            },
            {
                id: 93,
                label: 'MENUITEMS.FORMS.LIST.WIZARD',
                link: '/',
                parentId: 88
            },
            {
                id: 94,
                label: 'MENUITEMS.FORMS.LIST.MASK',
                link: '/',
                parentId: 88
            }
        ]
    },
    {
        id: 95,
        icon: 'database',
        label: 'MENUITEMS.TABLES',
        subItems: [
            {
                id: 96,
                label: 'MENUITEMS.TABLES.LIST.BASIC',
                link: '/',
                parentId: 95
            },
            {
                id: 97,
                label: 'MENUITEMS.TABLES.LIST.ADVANCEDTABLES',
                link: '/',
                parentId: 95
            }
        ]
    },
    {
        id: 98,
        icon: 'bar-chart-2',
        label: 'MENUITEMS.APEXCHARTS',
        subItems: [
            {
                id: 99,
                label: 'MENUITEMS.APEXCHARTS.LIST.LINE',
                link: '/',
                parentId: 98
            },
            {
                id: 100,
                label: 'MENUITEMS.APEXCHARTS.LIST.AREA',
                link: '/',
                parentId: 98
            },
            {
                id: 101,
                label: 'MENUITEMS.APEXCHARTS.LIST.COLUMN',
                link: '/',
                parentId: 98
            },
            {
                id: 102,
                label: 'MENUITEMS.APEXCHARTS.LIST.BAR',
                link: '/',
                parentId: 98
            },
            {
                id: 103,
                label: 'MENUITEMS.APEXCHARTS.LIST.MIXED',
                link: '/',
                parentId: 98
            },
            {
                id: 104,
                label: 'MENUITEMS.APEXCHARTS.LIST.TIMELINE',
                link: '/',
                parentId: 98
            },
            {
                id: 105,
                label: 'MENUITEMS.APEXCHARTS.LIST.CANDLESTICK',
                link: '/',
                parentId: 98
            },
            {
                id: 106,
                label: 'MENUITEMS.APEXCHARTS.LIST.BOXPLOT',
                link: '/',
                parentId: 98
            },
            {
                id: 107,
                label: 'MENUITEMS.APEXCHARTS.LIST.BUBBLE',
                link: '/',
                parentId: 98
            },
            {
                id: 108,
                label: 'MENUITEMS.APEXCHARTS.LIST.SCATTER',
                link: '/',
                parentId: 98
            },
            {
                id: 109,
                label: 'MENUITEMS.APEXCHARTS.LIST.HEATMAP',
                link: '/',
                parentId: 98
            },
            {
                id: 110,
                label: 'MENUITEMS.APEXCHARTS.LIST.TREEMAP',
                link: '/',
                parentId: 98
            },
            {
                id: 111,
                label: 'MENUITEMS.APEXCHARTS.LIST.PIE',
                link: '/',
                parentId: 98
            },
            {
                id: 112,
                label: 'MENUITEMS.APEXCHARTS.LIST.RADIALBAR',
                link: '/',
                parentId: 98
            },
            {
                id: 113,
                label: 'MENUITEMS.APEXCHARTS.LIST.RADAR',
                link: '/',
                parentId: 98
            },
            {
                id: 114,
                label: 'MENUITEMS.APEXCHARTS.LIST.POLARAREA',
                link: '/',
                parentId: 98
            }
        ]
    },
    {
        id: 115,
        label: 'MENUITEMS.ICONS',
        icon: 'archive',
        subItems: [
            {
                id: 116,
                label: 'MENUITEMS.ICONS.LIST.UNICONS',
                link: '/',
                parentId: 115
            },
            {
                id: 117,
                label: 'MENUITEMS.ICONS.LIST.FEATHERICONS',
                link: '/',
                parentId: 115
            },
            {
                id: 118,
                label: 'MENUITEMS.ICONS.LIST.BOXICONS',
                link: '/',
                parentId: 115
            },
            {
                id: 119,
                label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
                link: '/',
                parentId: 115
            },
            {
                id: 120,
                label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
                link: '/',
                parentId: 115
            },
        ]
    },
    {
        id: 121,
        label: 'MENUITEMS.MAPS',
        icon: 'map-pin',
        subItems: [
            {
                id: 122,
                label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
                link: '/',
                parentId: 121
            },
            {
                id: 123,
                label: 'MENUITEMS.MAPS.LIST.LEAFLET',
                link: '/',
                parentId: 121
            }
        ]
    },
    {
        id: 124,
        label: 'MENUITEMS.MULTILEVEL',
        icon: 'share-2',
        subItems: [
            {
                id: 125,
                label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
                parentId: 124
            },
            {
                id: 126,
                label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
                subItems: [
                    {
                        id: 127,
                        label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
                        parentId: 126,
                    },
                    {
                        id: 128,
                        label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
                        parentId: 126,
                    }
                ]
            },
        ]
    }
];
*/

