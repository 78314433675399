import { TranslateService } from '@ngx-translate/core';
import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, finalize, map} from "rxjs/operators";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import { Router } from '@angular/router';
import {environment} from 'src/environments/environment'


export interface AuthData {
  token: string
}
@Injectable({
  providedIn: 'root'
})
export class RestService {
  restURLs: any = environment.restURL;
  tkn = "";
  lang = "";
  baseOPT = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: ''
    }
  };
  public tknSubj = new BehaviorSubject<null | AuthData>(null)
  token$ = this.tknSubj.asObservable()

  constructor(public router: Router, private httpClient: HttpClient, public translate: TranslateService) {
    let sessiontkn = sessionStorage.getItem("tkn");
    sessiontkn && sessiontkn != "" ? this.tkn = sessiontkn : this.logout();
  }

  logout() {
    this.tkn = "",
    sessionStorage.removeItem("tkn");
    this.baseOPT.headers.Authorization = "",
    this.router.navigate(["/login"]);
  }

  public getService(base:string, ctx:string, opt?:any): Observable<any> {
    !opt ? opt = this.baseOPT : null;

    this.tkn && this.tkn!='' ? this.baseOPT.headers.Authorization = "Bearer "+this.tkn : this.logout();
    return this.httpClient.get(this.restURLs[base] + '' + ctx, opt).pipe(
        catchError((err) => {
          console.log('error caught in service');
          console.error(err.message);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public postService(base:string, ctx:string, json: any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    this.tkn && this.tkn!='' ? this.baseOPT.headers.Authorization = "Bearer "+this.tkn : this.logout();
    return this.httpClient.post(this.restURLs[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public putService(base:string, ctx:string, json: any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.put(this.restURLs[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public deleteService(base:string, ctx:string, json: any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.delete(this.restURLs[base] + '' + ctx, {responseType:'text'}).pipe(
        catchError((err) => {
          console.debug('error caught in service',err);
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.debug()
    return (error: any): Observable<T> => {

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.debug(message);
  }


}
